//import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
//import styles
import "../../assets/styles/navbars/layoutNavigation.scss";

//import components
import { MaterialIcons } from "./dataDisplay/UIElements";
import { Dropdown, Menu } from 'antd';

//import utils
import { getMobileNavigationMenu, getNavigationMenu } from "../utils/helperFunctions";
import { history } from "../config/history";
import { MENU_TYPES } from "../../admin/constants/constants";

import { ChangeLanguage, GetLanguages, GetMenuWeb } from "../redux/actions";
import { mapStateToProps } from "../../admin/redux/mapStateToProps";
import { generateMediaUrl } from "../utils/generateMediaUrl";
import { createUrl } from "../utils/createUrl";

class LayoutNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            mobileMenuOpen: false,
            searchActive: false,
            navigationMenuHidden: false,
            searchClosed: true,
            mobileMenu: [],
            isActive: false,
        };
        this.getSearchText = this.getSearchText.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
        this.openSearch = this.openSearch.bind(this);
        this.closeSearch = this.closeSearch.bind(this);
    }

    async componentDidMount() {
        await this.props.GetMenuWeb();
        await this.props.GetMenuWeb(MENU_TYPES.RIGHT);
        this.setState({
            mobileMenu: [
                ...this.props.menuList,
                ...this.props.rightMenu,
            ]
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedLanguage } = this.props;
        const { mobileMenuOpen } = this.state;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetMenuWeb();
        }
        if (prevProps.location.pathname !== this.props.location.pathname) {
            mobileMenuOpen && this.toggleMobileMenu();
        }
    }

    toggleMobileMenu() {
        this.setState(state => ({
            mobileMenuOpen: !state.mobileMenuOpen
        }));
    };

    getSearchText(e) {
        const text = e.target.value;
        this.setState({
            searchText: text
        }, () => !text && this.onSearch())
    }

    onSearch() {
        const { searchText } = this.state;
        history.push({
            pathname: createUrl('search'),
            search: `?${searchText}`,
            state: { searchText }
        })
    }

    openSearch(e) {
        e.preventDefault();
        this.setState({
            navigationMenuHidden: true,
            searchClosed: false,
        })
    }

    closeSearch(e) {
        e.preventDefault();
        this.setState({
            navigationMenuHidden: false,
            searchClosed: true,
        })
    }

    render() {
        const { mobileMenuOpen, navigationMenuHidden, searchClosed, mobileMenu, searchText } = this.state;

        const { languages, selectedLanguage, menuList, translation } = this.props;

        const dropdownContent = <Menu>
            {
                languages && languages.map((item) => {
                    return selectedLanguage.code !== item.code &&
                        <Menu.Item key={item.id} onClick={() => {
                            this.props.ChangeLanguage(item)
                        }}
                            className={'language-dropdown-item'}>
                            {
                                item.iconPath && item.iconPath.path &&
                                <img className={'language-icon'}
                                    src={generateMediaUrl(item.iconPath.path)} alt="img" />
                            }
                            {item.name}
                        </Menu.Item>
                })
            }
        </Menu>;
        return (
            <div className="home-page-header">
                <div className="header">
                    <Link to={'/'}>
                        <img src={require('../../assets/images/header/am_logo.png')}
                            className="header-logo-left" alt="" />
                    </Link>
                    <div className="header-inner">
                        <p className="header-text">{translation && translation['header-ministry-name']}</p>
                        <p className="header-title">{translation && translation['header-website-name']}</p>
                    </div>
                    <Link to={'/'}>
                        {/* <img src={require('../../assets/images/header/ced_logo.png')} */}
                        <img src={require('../../assets/images/header/logo.png')}
                            className="header-logo-right" alt="" />
                    </Link>
                </div>
                <nav>
                    <ul>
                        {menuList && !!menuList.length && getNavigationMenu(menuList, searchClosed, navigationMenuHidden)}
                        <li onClick={this.openSearch}
                            className={`list-item search-icon ${searchClosed ? 'is-closed' : ''} ${navigationMenuHidden ? 'hidden' : ''}`}>
                            <img src={require('../../assets/images/ic_search.svg')} alt="" />
                        </li>
                        {selectedLanguage && selectedLanguage.name &&
                            <li className={`list-item language-selector ${searchClosed ? 'is-closed' : ''} ${navigationMenuHidden ? 'hidden' : ''}`}>
                                <Dropdown overlay={dropdownContent}>
                                    <span className="ant-dropdown-link menu-language">
                                        {selectedLanguage.iconPath && selectedLanguage.iconPath.path
                                            && <img className={'language-icon'}
                                                src={generateMediaUrl(selectedLanguage.iconPath.path)} alt="img" />}
                                        <span>{selectedLanguage.name}</span>
                                        <img
                                            src={require('../../assets/images/icons/ic_drop_down_gray.svg')}
                                            alt="" />
                                    </span>
                                </Dropdown>
                            </li>}
                        <li className={`menu-search-container ${navigationMenuHidden ? 'active' : ''}`}>
                            <div className="menu-search-input">
                                <img onClick={this.onSearch}
                                    src={require('../../assets/images/ic_search.svg')} alt="" />
                                <input value={searchText}
                                    onChange={this.getSearchText}
                                    className="menu-search-input"
                                    type="text"
                                    onKeyDown={(e) => (e.key === 'Enter' && this.onSearch())}
                                    placeholder={translation && translation['header-search-placeholder']} />
                            </div>
                            <div className="menu-search-close"
                                onClick={this.closeSearch}>
                                <MaterialIcons name={'close'} />
                            </div>
                        </li>
                    </ul>

                    <button className={`btn ${mobileMenuOpen ? 'menu-is-open' : ''}`} onClick={this.toggleMobileMenu}>
                        <MaterialIcons name={"menu"} />
                    </button>
                </nav>

                <div
                    className={`navMenu custom-scrollbar-y ${mobileMenuOpen ? 'menu-opened' : ''}`}>
                    <Menu className={'navigation-menu'} mode="inline">
                        {mobileMenu && !!mobileMenu.length && getMobileNavigationMenu(mobileMenu, mobileMenuOpen)}
                        <li className="ant-menu-item menu-item" style={{
                            transitionDelay: (mobileMenu.length * 0.06) + 's',
                            paddingLeft: '24px'
                        }}>
                            <button
                                onClick={() => history.push('/vacancies')}>{translation && translation['right-menu-button-text']}</button>
                        </li>
                    </Menu>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    GetLanguages,
    ChangeLanguage,
    GetMenuWeb
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutNavigation));
